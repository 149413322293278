export default [
  {
    path: '/vehicleTypes',
    name: 'vehicle-types-list',
    component: () => import('@/views/vehicle-types/vehicle-types-list/VehicleTypesList.vue'),
    meta: {
      resource: 'VehicleTypes',
      action: 'read',
      breadcrumb: [
        {
          text: 'Vehicle Types',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicleTypes/:id/walkaroundChecks',
    name: 'vehicle-type-walkaround-checks',
    component: () => import('@/views/question/vehicle-types/QuestionsList.vue'),
  },
]