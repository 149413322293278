var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"edit-vehicle-sidebar","visible":_vm.isEditVehicleSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-edit-vehicle-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Edit Vehicle Info ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleUpdateVehicleInfo)}}},[_c('b-form-group',{attrs:{"label":"Registration","label-for":"registration"}},[_c('validation-provider',{attrs:{"name":"Registration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"registration","placeholder":"Enter Vehicle Registration"},model:{value:(_vm.newVehicleData.registration),callback:function ($$v) {_vm.$set(_vm.newVehicleData, "registration", $$v)},expression:"newVehicleData.registration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Make","label-for":"make"}},[_c('validation-provider',{attrs:{"name":"Make","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"make","placeholder":"Enter Vehicle Make"},model:{value:(_vm.newVehicleData.make),callback:function ($$v) {_vm.$set(_vm.newVehicleData, "make", $$v)},expression:"newVehicleData.make"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Model","label-for":"model"}},[_c('validation-provider',{attrs:{"name":"Model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"model","placeholder":"Enter Vehicle Model"},model:{value:(_vm.newVehicleData.model),callback:function ($$v) {_vm.$set(_vm.newVehicleData, "model", $$v)},expression:"newVehicleData.model"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Mileage","label-for":"mileage"}},[_c('validation-provider',{attrs:{"name":"Mileage","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mileage","placeholder":"Enter Vehicle Mileage"},model:{value:(_vm.newVehicleData.mileage),callback:function ($$v) {_vm.$set(_vm.newVehicleData, "mileage", $$v)},expression:"newVehicleData.mileage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-form-checkbox',{staticClass:"mt-50 custom-control-warning",attrs:{"disabled":_vm.newVehicleData.mileage >= _vm.initialMileage,"value":true,"unchecked-value":false},model:{value:(_vm.mileageCorrectionOverride),callback:function ($$v) {_vm.mileageCorrectionOverride=$$v},expression:"mileageCorrectionOverride"}},[_vm._v(" Override mileage ")])],1),_c('b-form-group',{attrs:{"label":"Type","label-for":"vehicle-type-data"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.vehicleTypeOptions,"reduce":function (val) { return val; },"clearable":false,"label":"name","input-id":"vehicle-type-data"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(" "+_vm._s(name)+" ")])]}}],null,true),model:{value:(_vm.newVehicleData.vehicleType),callback:function ($$v) {_vm.$set(_vm.newVehicleData, "vehicleType", $$v)},expression:"newVehicleData.vehicleType"}})],1),(!!_vm.newVehicleData)?_c('div',[_c('b-card',{staticClass:"border-primary",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"d-flex justify-content-between pt-75 pb-25 px-1"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.newVehicleData.registration)+" ")])]),_c('b-card-body',{staticClass:"px-1"},[_c('ul',{staticClass:"list-unstyled my-1"},[_c('li',[_c('span',{staticClass:"align-middle"},[_vm._v("Make: "+_vm._s(_vm.newVehicleData.make ? _vm.newVehicleData.make : 'N/A'))])]),_c('li',[_c('span',{staticClass:"align-middle"},[_vm._v("Model: "+_vm._s(_vm.newVehicleData.model ? _vm.newVehicleData.model : 'N/A'))])]),_c('li',[_c('span',{staticClass:"align-middle"},[_vm._v("Mileage: "+_vm._s(_vm.newVehicleData.mileage ? _vm.newVehicleData.mileage + ' mi' : 'N/A'))])]),_c('li',[_c('span',{staticClass:"align-middle"},[_vm._v("Type: "+_vm._s(_vm.newVehicleData.vehicleType ? _vm.newVehicleData.vehicleType.name : 'N/A'))])])])])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-50",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.isLoading ? "Updating..." : "Update")+" ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }