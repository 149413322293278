<template>
  <div>
    <b-card>

          <b-card-text>
            <span class="font-weight-bold">Checks</span>
          </b-card-text>


        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-for="answer in answers"
            :key="answer.id"
            :variant="answer.success ? '' : 'warning'"
          >
              <span>
                {{ answer.question.question }}
              </span>
            <b-badge
              :variant="resolveVehicleCheckAnswerVariant(answer.success)"
            >
              {{ resolveVehicleCheckAnswerText(answer.success) }}
            </b-badge>
          </b-list-group-item>
        </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BSpinner,
  BDropdown,
  BDropdownItem,
  VBModal,
  BListGroup,
  BListGroupItem,
  BBadge
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import useVehicleCheckList from "@/views/vehicle-check/useVehicleCheckList"
export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    BListGroup,
    BListGroupItem,
    BBadge
  },
  props: {
    answers: {
      type: Array,
      required: true,
    },
  },
  mounted() {

  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      resolveVehicleCheckAnswerVariant,
      resolveVehicleCheckAnswerText,
    } = useVehicleCheckList()

    return {
      perfectScrollbarSettings,
      resolveVehicleCheckAnswerVariant,
      resolveVehicleCheckAnswerText
    }
  },
}
</script>

