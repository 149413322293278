import axios from '@axios'

export default {
  namespaced: true,
  state: {
    jobs: [],
    total: 0,
    vehicleActive: false,
  },
  getters: {
    getTotal: state => state.total,
    isVehicleActive: state => state.vehicleActive,
  },
  mutations: {
    SET_VEHICLE_ACTIVE(state, payload) {
      state.vehicleActive = payload
    },
    ADD_JOB(state, job) {
      state.jobs.push(job)
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    UPDATE_JOB(state, updatedJob) {
      state.jobs = state.jobs.map(job =>
      job.id === updatedJob.id
        ? { ...job, ...updatedJob }
        : job
      )
    },
    CLEAR_JOBS(state) {
      state.jobs = []
    },
    REMOVE_JOB(state, id) {
      state.jobs = state.jobs.filter(job => job.id !== id)
    }
  },
  actions: {
    fetchJobs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobs', { params: queryParams })
          .then(response => {
            ctx.commit('CLEAR_JOBS')
            response.data.jobs.forEach(job => {
              ctx.commit('ADD_JOB', job)
            })
            ctx.commit('SET_TOTAL', response.data.meta.total)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchJob(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/jobs/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteJob(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/jobs/${id}`)
          .then(response => {
            ctx.commit('REMOVE_JOB', id)
            ctx.commit('SET_TOTAL', ctx.state.total - 1)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateJob(ctx, { jobId, jobData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/jobs/${jobId}`, jobData)
          .then(response => {
            ctx.commit('UPDATE_JOB', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addJob(ctx, { jobData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/jobs', jobData)
          .then(response => {
            ctx.commit('ADD_JOB', response.data)
            ctx.commit('SET_TOTAL', ctx.state.total + 1)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateJobStatus(ctx, { jobId, status }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/jobs/${jobId}/status`, { status })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchJobTemplates() {
      return new Promise((resolve, reject) => {
        axios
          .get('/jobTemplates', { params: { showAll: true } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateJobCustomer(ctx, { jobId, customerId }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/jobs/${jobId}/customer`, { customerId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addJobCheckpoint(ctx, { jobId, checkpointData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/jobs/${jobId}/checkpoints`, checkpointData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}