export default function useVehicleCheckList() {

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveVehicleCheckOutcomeVariant = active => {
    if (active === true) return 'primary'
    return 'warning'
  }

  const resolveVehicleCheckOutomeIcon = active => {
    if (active === true) return 'CheckIcon'
    return 'XIcon'

  }

  const resolveVehicleCheckType = type => {
    switch (type) {
      case 'type.mileage': return 'Odometer'
      case 'type.full': return 'Walkaround'
    }
  }

  const resolveVehicleCheckAnswerVariant = success => {
    if (success === true) return 'success'
    return 'warning'
  }

  const resolveVehicleCheckAnswerText = success => {
    if (success === true) return 'Pass'
    return 'Fail'
  }

  return {
    resolveVehicleCheckOutcomeVariant,
    resolveVehicleCheckOutomeIcon,
    resolveVehicleCheckType,
    resolveVehicleCheckAnswerVariant,
    resolveVehicleCheckAnswerText
  }
}
