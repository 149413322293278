import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'firstName', label: 'user', sortable: true },
    { key: 'email', sortable: true },
    { key: 'role', sortable: true },
    {
      key: 'emailVerified',
      label: 'Verified',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(100)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('firstName')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        limit: perPage.value,
        page: currentPage.value,
        sort: `u.${sortBy.value}`,
        direction: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { users, meta } = response.data

        callback(users)
        totalUsers.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'User') return 'primary'
    if (role === 'Driver') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'Admin') return 'success'
    if (role === 'Super Admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'User') return 'UserIcon'
    if (role === 'Driver') return 'UserIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'Admin') return 'SettingsIcon'
    if (role === 'Super Admin') return 'MehIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'verified') return 'success'
    if (status === 'blocked') return 'secondary'
    return 'primary'
  }

  const resolveUserEmailVerifiedIcon = emailVerified => {
    if (emailVerified) return 'CheckIcon'
    return 'XIcon'
  }

  const resolveUserEmailVerifiedVariant = emailVerified => {
    if (emailVerified) return 'success'
    return 'danger'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserEmailVerifiedIcon,
    resolveUserEmailVerifiedVariant,
    refetchData,
  }
}