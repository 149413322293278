<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
        <h5 class="mb-0">
          Generate Report
        </h5>
      </b-card-header>

      <b-card-body>
        <b-row
          align-h="end"
          class="mx-auto justify-content-between"
        >
            <!-- Form -->
            <b-form
              class="pt-2"
              @submit.prevent="generateReport"
            >
              <b-form-group
                label="Start Date"
                label-for="startDate"
              >
                <flat-pickr
                  v-model="reportData.startDate"
                  class="form-control"
                  :config="dateTimePickerConfig"
                />
              </b-form-group>
              <b-form-group
                label="End Date"
                label-for="endDate"
              >
                <flat-pickr
                  v-model="reportData.endDate"
                  class="form-control"
                  :config="dateTimePickerConfig"
                />
              </b-form-group>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="isLoading || reportData.startDate === null || reportData.endDate === null"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                    class="mr-50"
                  />
                  {{ isLoading ? "Generating..." : "Generate" }}
                </b-button>
              </div>
            </b-form>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCardBody,
  BBadge,
  BButton,
  BFormGroup,
  BForm,
  BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import JobEditDriver from '@/views/job/jobs-edit/JobEditDriver.vue'
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
import flatPickr from 'vue-flatpickr-component'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BFormGroup,
    BForm,
    BButton,
    BRow,
    BSpinner,
    JobEditDriver,
    flatPickr
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false
    },
  },
  data() {
    return {
      reportData: {
        startDate: null,
        endDate: null
      }
    }
  },
  methods: {
    generateReport() {
      this.$emit('handleGenerateReport', this.reportData)
    },
  },
  setup() {
    const dateTimePickerConfig = {
      enableTime: false,
      dateFormat: 'Y-m-d',
      defaultHour: 0,
      altInput: true,
      maxDate: 'today',
      altFormat: 'Y-m-d',
      plugins: [new ConfirmDatePlugin({})],
    }
    return {
      dateTimePickerConfig,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
