import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomerList() {
  // Use toast
  const toast = useToast()

  const refCustomerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'firstName', label: 'Name', sortable: true },
    { key: 'email', label: 'Email', sortable: true },
    { key: 'phoneNumber', label: 'Phone Number', sortable: true },
    { key: 'jobCount', label: 'Jobs' },
    { key: 'actions' },
  ]
  const perPage = ref(100)
  const totalCustomers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('firstName')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    }
  })

  const refetchData = () => {
    refCustomerListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchCustomers = (ctx, callback) => {
    store
      .dispatch('app-customer/fetchCustomers', {
        limit: perPage.value,
        page: currentPage.value,
        sort: `c.${sortBy.value}`,
        direction: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { customers, meta } = response.data

        callback(customers)
        totalCustomers.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching customers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCustomers,
    tableColumns,
    perPage,
    currentPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerListTable,
  }
}