export default function useJobProgressList() {
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTimelineTypeText = type => {
    switch (type) {
      case 'type.mileage': return 'Mileage Record'
      case 'type.pickUp': return 'Pick Up'
      case 'type.dropOff': return 'Drop Off'
      case 'type.signature': return 'Signature'
      default: return 'Unknown'
    }
  }

  const resolveTimelineTypeIcon = type => {
    switch (type) {
      case 'type.mileage': return 'CheckCircleIcon'
      case 'type.pickUp': return 'MapPinIcon'
      case 'type.dropOff': return 'MapPinIcon'
      case 'type.signature': return 'PenToolIcon'
      default: return 'Unknown'
    }
  }

  const resolveTimelineStatusText = status => {
    switch (status) {
      case 'status.notStarted': return 'Not Started'
      case 'status.inProgress': return 'In Progress'
      case 'status.completed': return 'Completed'
      case 'status.failed': return 'Failed'
      default: return 'Unknown'
    }
  }

  const resolveTimelineStatusVariant = status => {
    switch (status) {
      case 'status.notStarted': return 'primary'
      case 'status.inProgress': return 'warning'
      case 'status.completed': return 'success'
      case 'status.failed': return 'danger'
      default: return 'secondary'
    }
  }

  return {
    resolveTimelineTypeText,
    resolveTimelineTypeIcon,
    resolveTimelineStatusText,
    resolveTimelineStatusVariant,
  }
}
