<template>
  <b-sidebar
    id="edit-job-sidebar"
    :visible="isEditJobSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-job-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Job Info
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleUpdateJobInfo)"
        >

          <!-- User Role -->
          <b-form-group
            label="Job Template"
            label-for="job-template-data"
          >
            <v-select
              v-model="newJobData.jobTemplate"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="jobTemplateOptions"
              :reduce="val => val"
              :clearable="false"
              label="alias"
              input-id="job-template"
            >
              <template #option="{ alias }">
                <div class="d-flex justify-content-between align-items-center">
                  {{ alias }}
                </div>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group
            label="Job Alias"
            label-for="alias"
          >
            <validation-provider
              #default="{ errors }"
              name="Job Alias"
              rules="required"
            >
              <b-form-input
                id="postalCode"
                v-model="newJobData.alias"
                placeholder="Enter Job Alias"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Scheduled Date"
            label-for="scheduled"
          >
            <flat-pickr
              v-model="newJobData.scheduled"
              class="form-control"
              :config="dateTimePickerConfig"
            />
          </b-form-group>

          <b-form-group
            label="Description"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
            >
              <b-form-textarea
                id="textarea-default"
                v-model="newJobData.description"
                placeholder="Description"
                rows="3"
                max-rows="30"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div v-if="!!newJobData">
            <b-card
              no-body
              class="border-primary"
            >
              <b-card-header class="d-flex justify-content-between pt-75 pb-25 px-1">
                <h3 class="mb-0">
                  {{ newJobData.alias }}
                </h3>
              </b-card-header>
              <b-card-body class="px-1">
                <ul
                  class="list-unstyled my-1"
                >
                  <li>
                    <span class="align-middle">Job Type: {{ newJobData.jobTemplate ? newJobData.jobTemplate.alias : 'Not selected' }}</span>
                  </li>
                  <li>
                    <span class="align-middle">Description: {{ newJobData.description ? newJobData.description : 'N/A' }}</span>
                  </li>
                </ul>
              </b-card-body>
            </b-card>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                class="mr-50"
              />
              {{ isLoading ? "Updating..." : "Update" }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="flat-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BSidebar,
    BForm,
    BFormInput,
    flatPickr,
    BCard,
    BCardBody,
    BCardHeader,
    BSpinner,
    BFormGroup,
    BButton,
    vSelect,
    BFormTextarea,
    // Form Validation
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditJobSidebarActive',
    event: 'update:is-edit-job-sidebar-active',
  },
  props: {
    isEditJobSidebarActive: {
      type: Boolean,
      required: true,
    },
    jobData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      newJobData: this.jobData ? this.jobData : {},
      isLoading: false,
    }
  },
  methods: {
    handleUpdateJobInfo() {
      const jobData = {
        alias: this.newJobData.alias,
        jobTemplate: this.newJobData.jobTemplate.id,
        description: this.newJobData.description,
        scheduled: this.newJobData.scheduled ? moment(this.newJobData.scheduled).format() : null,
      }
      const jobId = this.jobData ? this.jobData.id : null

      if (jobId) return this.updateJobInfo(jobId, jobData)
      return this.addJob(jobData)
    },
    addJob(jobData) {
      this.isLoading = true
      this.$emit('update:is-edit-job-sidebar-active', false)
      store.dispatch('app-job/addJob', { jobData })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job added',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully added a new job.',
            },
          })

          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while adding this job.',
            },
          })
          this.isLoading = false
        })
    },
    updateJobInfo(jobId, jobData) {
      this.isLoading = true
      store.dispatch('app-job/updateJob', { jobId, jobData })
        .then(response => {
          this.newJobData = response.data
          this.$emit('updateJobInfo', this.newJobData)
          this.$emit('update:is-edit-job-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this job.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$emit('update:is-edit-job-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating the job.',
            },
          })
          this.isLoading = false
        })
    },
  },
  setup(props, { emit }) {
    const jobTemplateOptions = ref([])

    store.dispatch('app-job/fetchJobTemplates')
      .then(response => {
        jobTemplateOptions.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          jobTemplateOptions.value = undefined
        }
      })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const dateTimePickerConfig = {
      enableTime: true,
      dateFormat: 'Z',
      altInput: true,
      altFormat: 'Y-m-d H:i',
      minDate: 'today',
      time_24hr: true,
      plugins: [new ConfirmDatePlugin({})],
    }
    return {
      jobTemplateOptions,
      refFormObserver,
      getValidationState,
      dateTimePickerConfig,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#edit-job-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>