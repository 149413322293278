<template>
  <div>
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :variant="`light-${resolveVehicleCheckOutcomeVariant(viewVehicleCheckData.outcome)}`"
              size="104px"
              rounded
            >
              <feather-icon
                :icon="resolveVehicleCheckOutomeIcon(viewVehicleCheckData.outcome)"
                size="70"
              />
            </b-avatar>
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  Vehicle Check
                </h4>
                <span class="card-text">{{ viewVehicleCheckData.vehicle.registration }}</span>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Outcome</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ viewVehicleCheckData.outcome ? 'Successful' : 'Unsuccessful' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="SendIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Odometer</span>
              </th>
              <td class="pb-50">
                {{ viewVehicleCheckData.mileage + ' mi' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Performed By</span>
              </th>
              <td class="pb-50">
                {{ viewVehicleCheckData.driver.fullName }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Type</span>
              </th>
              <td class="pb-50">
                {{ resolveVehicleCheckType(viewVehicleCheckData.checkType) }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div>
            <hr/>
          </div>
          <b-card-text>
            <span class="font-weight-bold">Note</span>
            <div class="mt-2">
              {{ viewVehicleCheckData.note !== undefined ? viewVehicleCheckData.note: "" }}
            </div>
            <b-row
              align-h="end"
              class="text-muted w-100 mt-2 mx-0"
            >
              <b-col
                class="text-right"
              >
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ toHumanDate(viewVehicleCheckData.createdAt) }}</span>
              </b-col>
            </b-row>
          </b-card-text>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import toHumanDate from '@/libs/utils/general/dates'
import { ref, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vehicleEditInfo from '@/views/vehicle/vehicles-edit/VehicleEditInfo.vue'
import useVehicleCheckList from "@/views/vehicle-check/useVehicleCheckList"

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vehicleEditInfo,
  },
  props: {
    vehicleCheckData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewVehicleCheckData: this.$props.vehicleCheckData,
      isLoading: false,
      inactiveVehicleCheckbox: false,
    }
  },
  methods: {
  },
  setup() {
    const {
      resolveVehicleCheckOutcomeVariant,
      resolveVehicleCheckOutomeIcon,
      resolveVehicleCheckType
    } = useVehicleCheckList()
    return {
      avatarText,
      toHumanDate,
      resolveVehicleCheckOutcomeVariant,
      resolveVehicleCheckOutomeIcon,
      resolveVehicleCheckType
    }
  },
}
</script>

<style>

</style>
