export default [
  {
    path: '/questions',
    name: 'questions-list',
    component: () => import('@/views/question/vehicle-types/QuestionsList.vue'),
    meta: {
      resource: 'Questions',
      action: 'read',
      breadcrumb: [
        {
          text: 'Questions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/questions/view/:id',
    name: 'questions-view',
    component: () => import('@/views/question/questions-view/QuestionView.vue'),
  },
]