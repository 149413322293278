import axios from '@axios'

export default {
  namespaced: true,
  state: {
    questions: [],
  },
  getters: {
  },
  mutations: {
    ADD_QUESTION(state, vehicleTypeQuestion) {
      state.questions.push(vehicleTypeQuestion)
    },
    CLEAR_QUESTIONS(state) {
      state.questions = []
    },
    REMOVE_QUESTION(state, id) {
      state.questions = state.questions.filter(vehicleTypeQuestion => vehicleTypeQuestion.id !== id)
    },
    UPDATE_QUESTION(state, updatedVehicleTypeQuestion) {
      state.questions = state.questions.map(vehicleTypeQuestion =>
        vehicleTypeQuestion.id === updatedVehicleTypeQuestion.id
          ? {...vehicleTypeQuestion, ...updatedVehicleTypeQuestion}
          : vehicleTypeQuestion
      )
    },

  },
  actions: {
    fetchQuestions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/vehicleCheckQuestions', { params: { ...queryParams, showActive: false } })
          .then(response => {
            ctx.commit('CLEAR_QUESTIONS')
            response.data.questions.forEach(question => {
              ctx.commit('ADD_QUESTION', question)
            })
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchVehicleTypeQuestions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const vehicleType = queryParams.vehicleType
        delete (queryParams.vehicleType)
        axios
          .get(`/vehicleTypes/${vehicleType}/vehicleCheckQuestions`, {params: {...queryParams}})
          .then(response => {
            ctx.commit('CLEAR_QUESTIONS')
            response.data.questions.forEach(question => {
              ctx.commit('ADD_QUESTION', question)
            })
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchQuestion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/vehicleCheckQuestions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteQuestion(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/vehicleCheckQuestions/${id}`)
          .then(response => {
            ctx.commit('REMOVE_QUESTION', id)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    updateQuestion(ctx, { questionId, questionData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/vehicleCheckQuestions/${questionId}`, questionData)
          .then(response => {
            ctx.commit('UPDATE_QUESTION', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateQuestionPosition(ctx, { id, newIndex }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/vehicleCheckQuestions/${id}/position`, { position: newIndex })
          .then(response => {
            ctx.commit('UPDATE_QUESTION', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addQuestion(ctx, {questionData}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/vehicleCheckQuestions', questionData)
          .then(response => {
            ctx.commit('ADD_QUESTION', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    toggleQuestionActive(ctx, { id, active }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/vehicleCheckQuestions/${id}/active`, { active })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    enableVehicleTypeQuestion(ctx, { id, vehicleCheckQuestionId }) {
      return new Promise((resolve, reject) => {
        axios.post(`/vehicleTypes/${id}/vehicleCheckQuestions`, { vehicleCheckQuestionId })
          .then(response => {
            ctx.commit('UPDATE_QUESTION', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    disableVehicleTypeQuestion(ctx, { id, vehicleCheckQuestionId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/vehicleTypes/${id}/vehicleCheckQuestions/${vehicleCheckQuestionId}`)
          .then(response => {
            ctx.commit('UPDATE_QUESTION', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    }
  },
}