<template>
  <b-sidebar
    id="edit-vehicle-type-sidebar"
    :visible="isEditVehicleTypeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-vehicle-type-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add VehicleType
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(handleUpdateVehicleTypeInfo)"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="newVehicleTypeData.name"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="VehicleType"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
            >
              <b-form-textarea
                id="textarea-default"
                v-model="newVehicleTypeData.description"
                placeholder="Description"
                rows="3"
                max-rows="30"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                class="mr-50"
              />
              {{ isLoading ? "Updating..." : "Update" }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="flat-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>

  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormInput,
  BBadge,
  BFormGroup,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BSpinner,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import useVehicleTypeList from "@/views/vehicle-types/useVehicleTypesList";

export default {
  components: {
    BSidebar,
    BForm,
    BFormInput,
    flatPickr,
    BCard,
    BCardBody,
    BCardHeader,
    BBadge,
    BSpinner,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    // Form Validation
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditVehicleTypeSidebarActive',
    event: 'update:is-edit-vehicle-type-sidebar-active',
  },
  props: {
    isEditVehicleTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    vehicleTypeData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      integer,
      newVehicleTypeData: {},
      isLoading: false,
    }
  },
  watch: {
    vehicleTypeData: {
      immediate: true,
      handler(newVal) {
        this.newVehicleTypeData = { ...newVal }
      },
    },
  },
  methods: {
    handleUpdateVehicleTypeInfo() {
      const vehicleTypeData = {
        name: this.newVehicleTypeData.name,
        description: this.newVehicleTypeData.description,
      }
      const vehicleTypeId = this.vehicleTypeData ? this.vehicleTypeData.id : null
      this.$emit('update:is-edit-vehicle-type-sidebar-active', false)
      if (vehicleTypeId) return this.updateVehicleType(vehicleTypeId, vehicleTypeData)
      return this.addVehicleType(vehicleTypeData)
    },
    updateVehicleType(vehicleTypeId, vehicleTypeData) {
      this.isLoading = true
      store.dispatch('app-vehicle-type/updateVehicleType', { vehicleTypeId, vehicleTypeData })
        .then(response => {
          // this.newVehicleTypeData = response.data
          this.$emit('updateVehicleTypeInfo', this.newVehicleTypeData)

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'VehicleType updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this vehicle type.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          if (vehicleTypeId) {
            store.dispatch('app-vehicle-type/fetchVehicleType', { id: vehicleTypeId })
              .then(response => {
                this.$emit('updateVehicleTypeInfo', response.data)
                this.newVehicleTypeData = response.data
              })
          }

          this.$emit('update:is-edit-vehicle-type-sidebar-active', false)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'VehicleType update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this vehicle type.',
            },
          })
          this.isLoading = false
        })
    },
    addVehicleType(vehicleTypeData) {
      this.isLoading = true
      store.dispatch('app-vehicle-type/addVehicleType', { vehicleTypeData })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'VehicleType added',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully added a new vehicle type.',
            },
          })

          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error adding new vehicle type',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while adding this vehicle type.',
            },
          })
          this.isLoading = false
        })
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      refVehicleTypeListTable,
    } = useVehicleTypeList()

    return {
      refFormObserver,
      getValidationState,
      refVehicleTypeListTable
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#edit-vehicle-type-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>