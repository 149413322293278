<template>

  <div>
    <div class="pb-50">
      <span class="mr-50 font-weight-bold">Address:</span>
      <span>{{ checkpointData.checkpoint.address }}</span>
    </div>
    <div class="pb-50">
      <span class="mr-50 font-weight-bold">Performed:</span>
      <span>{{ toHumanDate(checkpointData.checkpoint.createdAt) }}</span>
    </div>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      :disabled="checkpointData.images.length === 0"
      variant="primary"
      block
      class="col-xl-3 col-lg-4 col-md-8 col-sm-12 mt-1 mb-1"
      :to="{name: 'images-gallery', params: {resourceType: 'progressItem', resourceId: checkpointData.id}}"
    >
      View Images
    </b-button>

  </div>
</template>

<script>
import toHumanDate from '@/libs/utils/general/dates'
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    checkpointData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toHumanDate(date) {
      return toHumanDate(date)
    },
  },
}
</script>
