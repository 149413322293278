<template>

  <div>
    <question-edit-info
      :is-edit-question-sidebar-active.sync="isEditQuestionSidebarActive"
      :question-data="questionData"
    />
    <!-- Table Container Card -->
    <b-card>
      <div class="mx-2 mb-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            v-if="$router.currentRoute.params.id === undefined"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              variant="primary"
              @click="toggleAddQuestionSidebar"
            >
              <span class="text-nowrap">Add Question</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <draggable
        :list="questions"
        tag="ul"
        group="people"
        class="todo-task-list media-list list-group list-group-flush cursor-move"
        handle=".draggable-task-handle"
        @end="onDragEnd"
      >
        <b-list-group-item
          v-for="(question, index) in questions"
          :key="index"
          tag="li"
          class="d-flex justify-content-between align-items-center todo-item"
        >
          <div class="d-flex">
            <feather-icon
              v-if="$router.currentRoute.params.id === undefined"
              icon="MoveIcon"
              class="draggable-task-handle"
            />
            <div class="text-nowrap">
              <b-badge
                v-if="$router.currentRoute.params.id === undefined"
                :variant="`light-${resolveQuestionActiveVariant(question.active)}`"
              >
                {{ question.active ? 'Active' : 'Inactive' }}
              </b-badge>
              <b-badge
                v-if="$router.currentRoute.params.id !== undefined"
                :variant="`light-${resolveQuestionActiveVariant(question.vehicleTypeQuestionId !== null)}`"
              >
                {{ question.vehicleTypeQuestionId !== null ? 'Enabled' : 'Disabled' }}
              </b-badge>
            </div>
            <div class="ml-1">
              <b-card-text class="mb-0 font-weight-bold">
                {{ question.question }}
              </b-card-text>
            </div>
          </div>
          <b-dropdown
            variant="link"
            class="ml-auto"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
<!--              <feather-icon icon="MoreVerticalIcon" />-->
            </template>
            <b-dropdown-item
              v-if="$router.currentRoute.params.id === undefined"
              @click="updateQuestion(question)"
            >
              <feather-icon icon="Edit2Icon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="enableVehicleTypeQuestion(question.id)"
              v-if="$router.currentRoute.params.id !== undefined && question.vehicleTypeQuestionId === null"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Enable</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="disableVehicleTypeQuestion(question.id)"
              v-if="$router.currentRoute.params.id !== undefined && question.vehicleTypeQuestionId !== null"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Disable</span>
            </b-dropdown-item>
            <b-dropdown-item
              variant="text-danger"
              @click="deleteQuestion(question.id)"
            >
              <span class="text-danger">
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>Delete</span>
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </b-list-group-item>
      </draggable>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BRow, BCol, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BListGroupItem, BCardText, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useQuestionsList from "@/views/question/useQuestionsList";
import questionStoreModule from "@/views/question/questionStoreModule";
import questionEditInfo from "@/views/question/questions-edit/QuestionEditInfo";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {onMounted} from "@vue/composition-api/dist/vue-composition-api";

export default {
  components: {
    draggable,
    BListGroupItem,
    BCardText,
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAlert,
    questionEditInfo,

    vSelect,
  },
  beforeRouteLeave(to, from, next) {
    store.commit('app-question/CLEAR_QUESTIONS')
    next()
  },
  watch: {
    $route(to, from) {
      this.refetchData()
    }
  },
  methods: {
    onDragEnd(event) {
      const { oldIndex, newIndex } = event
      const movedItem = this.questions[newIndex]

      const updatedPosition = {
        id: movedItem.id,
        position: newIndex
      }

      store.dispatch('app-question/updateQuestionPosition', { id: updatedPosition.id, newIndex })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Question updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this question',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Question update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this question',
            },
          })
          this.isLoading = false
        })
    },
    toggleAddQuestionSidebar() {
      this.questionData = {}
      this.isEditQuestionSidebarActive = true
    },
    updateQuestion(question) {
      this.questionData = { ...question }
      this.isEditQuestionSidebarActive = true
    },
    enableVehicleTypeQuestion(vehicleCheckQuestionId) {
      store.dispatch('app-question/enableVehicleTypeQuestion', { vehicleCheckQuestionId, id: router.currentRoute.params.id })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle type Question updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this vehicle type\'s questions list.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
            Object.entries(err.response.data.errors).forEach(errors => {
              const field = errors[0]
              const messages = errors[1]
              const toUppercase = str => str.toUpperCase()
              messages.forEach(errorMessage => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                    icon: 'XIcon',
                    variant: 'warning',
                    text: errorMessage,
                  },
                })
              })
            })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle type question update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this question.',
            },
          })
          this.isLoading = false
        })
    },
    disableVehicleTypeQuestion(vehicleCheckQuestionId) {
      store.dispatch('app-question/disableVehicleTypeQuestion', { vehicleCheckQuestionId, id: router.currentRoute.params.id })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle type Question updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this vehicle type\'s questions list.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle Type update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this question',
            },
          })
          this.isLoading = false
        })
    },
    deleteQuestion(id) {
      this.isLoading = true
      store.dispatch('app-question/deleteQuestion', id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Question deleted',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully deleted this question.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Question update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while deleting this question.',
            },
          })
          this.isLoading = false
        })
    },
  },
  setup() {
    const questionData = ref({})
    const QUESTION_APP_STORE_MODULE_NAME = 'app-question'

    // Register module
    if (!store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.registerModule(QUESTION_APP_STORE_MODULE_NAME, questionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) {
        store.commit(`${QUESTION_APP_STORE_MODULE_NAME}/CLEAR_QUESTIONS`)
        return store.unregisterModule(QUESTION_APP_STORE_MODULE_NAME)
      }
    })

    onMounted(() => (
      refetchData()
    ));

    const {
      tableColumns,
      perPage,
      currentPage,
      totalQuestions,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refetchData,

      // UI
      resolveQuestionActiveVariant,
      questions,
    } = useQuestionsList()

    const isEditQuestionSidebarActive = ref(false)

    return {
      tableColumns,
      perPage,
      currentPage,
      totalQuestions,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refetchData,
      isEditQuestionSidebarActive,
      // Filter
      avatarText,

      // UI
      resolveQuestionActiveVariant,
      questions,
      questionData,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  top: 50%;
  transform: translateY(25%);
  visibility: hidden;
  cursor: move;
  left: -6px;
  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>