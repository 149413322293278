import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useVehicleList() {
  // Use toast
  const toast = useToast()

  const refVehicleListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'registration', label: 'Registration', sortable: true },
    { key: 'make', label: 'Make & Model', sortable: true },
    { key: 'mileage', label: 'Mileage', sortable: true },
    { key: 'active', label: 'Status', sortable: true },
    { key: 'jobCount', label: 'Jobs' },
    { key: 'currentDriver', label: 'Current Driver' },
    { key: 'vehicleType', label: 'Vehice Type' },
    { key: 'actions' },
  ]
  const perPage = ref(100)
  const totalVehicles = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('registration')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refVehicleListTable.value ? refVehicleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalVehicles.value,
    }
  })

  const refetchData = () => {
    refVehicleListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchVehicles = (ctx, callback) => {
    store
      .dispatch('app-vehicle/fetchVehicles', {
        limit: perPage.value,
        page: currentPage.value,
        sort: `v.${sortBy.value}`,
        direction: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { vehicles, meta } = response.data

        callback(vehicles)
        totalVehicles.value = meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching vehicles list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveVehicleActiveVariant = active => {
    if (active === true) return 'primary'
    if (active === false) return 'warning'
    return null
  }

  const resolveVehicleActiveIcon = active => {
    if (active === true) return 'CheckIcon'
    if (active === false) return 'XIcon'
    return null
  }

  const resolveVehicleStatus = status => {
    switch (status) {
      case 'status.inUse': return 'In use'
      case 'status.atBase': return 'At base'
      default: return 'Undetermined'
    }
  }

  return {
    fetchVehicles,
    tableColumns,
    perPage,
    currentPage,
    totalVehicles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refVehicleListTable,

    resolveVehicleActiveVariant,
    resolveVehicleActiveIcon,
    resolveVehicleStatus,
  }
}