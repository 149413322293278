import axios from '@axios'

export default {
  namespaced: true,
  state: {
    vehicleTypes: [],
    total: 0,
  },
  getters: {
    getTotal: state => state.total,
  },
  mutations: {
    ADD_VEHICLE_TYPE(state, vehicleType) {
      state.vehicleTypes.push(vehicleType)
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    UPDATE_VEHICLE_TYPE(state, updatedVehicleType) {
      state.vehicleTypes = state.vehicleTypes.map(vehicleType =>
        vehicleType.id === updatedVehicleType.id
          ? {...vehicleType, ...updatedVehicleType}
          : vehicleType
      )
    },
    CLEAR_VEHICLE_TYPES(state) {
      state.vehicleTypes = []
    },
    REMOVE_VEHICLE_TYPE(state, id) {
      state.vehicleTypes = state.vehicleTypes.filter(vehicleType => vehicleType.id !== id)
    }
  },
  actions: {
    fetchVehicleTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/vehicleTypes', { params: { ...queryParams } })
          .then(response => {
            ctx.commit('CLEAR_VEHICLE_TYPES')
            response.data.vehicleTypes.forEach(vehicleType => {
              ctx.commit('ADD_VEHICLE_TYPE', vehicleType)
            })
            ctx.commit('SET_TOTAL', response.data.meta.total)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    deleteVehicleType(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/vehicleTypes/${id}`)
          .then(response => {
            ctx.commit('REMOVE_VEHICLE_TYPE', id)
            ctx.commit('SET_TOTAL', ctx.state.total - 1)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    updateVehicleType(ctx, { vehicleTypeId, vehicleTypeData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/vehicleTypes/${vehicleTypeId}`, vehicleTypeData)
          .then(response => {
            ctx.commit('UPDATE_VEHICLE_TYPE', response.data)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addVehicleType(ctx, {vehicleTypeData}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/vehicleTypes', vehicleTypeData)
          .then(response => {
            ctx.commit('ADD_VEHICLE_TYPE', response.data)
            ctx.commit('SET_TOTAL', ctx.state.total + 1)

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}