<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="questionData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching question data
      </h4>
      <div class="alert-body">
        No question found with this question id. Check the
        <b-link
          class="alert-link"
          :to="{ name: 'questions-list'}"
        >
          Question List
        </b-link>
        for other questions.
      </div>
    </b-alert>

    <template v-if="questionData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <question-info-card
            :question-data="questionData"
          />
        </b-col>
      </b-row>
<!--      <b-row>-->
<!--        <b-col-->
<!--          cols="12"-->
<!--          xl="12"-->
<!--          lg="6"-->
<!--          md="6"-->
<!--        >-->
<!--          <question-check-history-card-->
<!--            :question-id="questionData.id"-->
<!--          />-->
<!--        </b-col>-->

<!--      </b-row>-->
      </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import questionStoreModule from '@/views/question/questionStoreModule'
import questionInfoCard from '@/views/question/questions-view/QuestionInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    questionInfoCard,
  },
  methods: {
    handleQuestionDataUpdate(data) {
      this.questionData = data
    },
  },
  setup() {
    const questionData = ref(null)

    const QUESTION_APP_STORE_MODULE_NAME = 'app-question'

    // Register module
    if (!store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.registerModule(QUESTION_APP_STORE_MODULE_NAME, questionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUESTION_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-question/fetchQuestion', { id: router.currentRoute.params.id })
      .then(response => {
        questionData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          questionData.value = undefined
        }
      })
    return {
      questionData,
    }
  },
}
</script>

<style>

</style>
