import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from'@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useQuestionList() {
  // Use toast
  const toast = useToast()

  const questions = computed(() => store.state['app-question'].questions)

  const refetchData = () => {
    if (router.currentRoute.params.id !== undefined) {
      fetchVehicleTypeQuestions()
    } else {
      fetchQuestions()
    }
  }

  const fetchQuestions = (ctx, callback) => {
    store
      .dispatch('app-question/fetchQuestions', {
        showAll: true
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching walkaround checks\' list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      });
  }

  const fetchVehicleTypeQuestions = (ctx) => {
    const vehicleTypeId = router.currentRoute.params.id;
    store
      .dispatch('app-question/fetchVehicleTypeQuestions', {
        limit: 1000,
        vehicleType: vehicleTypeId
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching walkaround checks\' list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }



  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveQuestionActiveVariant = active => {
    if (active === true) return 'primary'
    if (active === false) return 'warning'
    return null
  }

  const resolveVehicleTypeQuestionEnabledVariant = id => {
    if (active === true) return 'primary'
    if (active === false) return 'warning'
    return null
  }

  const resolveQuestionActiveIcon = active => {
    if (active === true) return 'CheckIcon'
    if (active === false) return 'XIcon'
    return null
  }

  return {
    fetchQuestions,
    fetchVehicleTypeQuestions,
    resolveQuestionActiveVariant,
    resolveQuestionActiveIcon,
    refetchData,
    questions,
  }
}