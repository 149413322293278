<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card>
          <b-col
            cols="8"
            class="px-0 mx-0"
          >
            <validation-observer
              ref="jobTemplateForm"
              #default="{invalid}"
            >
              <b-form @submit.prevent="handleUpdateJobTemplate">
                <!-- Media -->
                <b-media class="mb-2">
                  <template #aside>
                    <b-avatar
                      variant="light-primary"
                      size="104px"
                      rounded
                    >
                      <feather-icon
                        icon="ListIcon"
                        size="70"
                      />
                    </b-avatar>
                  </template>
                  <h4 class="mb-1">
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <b-form-group
                        label="Alias"
                        label-for="alias"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Alias"
                          rules="required|min:3"
                        >
                          <b-form-input
                            id="alias"
                            v-model="newJobTemplateData.alias"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </h4>
                </b-media>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="invalid || isLoading || checkpointOrderIllegal"
                  type="submit"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                    class="mr-50"
                  />
                  {{ newJobTemplateData.id ? 'Update' : 'Save' }}
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        xl="9"
        lg="8"
        md="7"
      >

        <b-card>
          <h6 class="text-primary font-weight-bold mb-2">
            Job Items
          </h6>
          <b-alert
            :show="checkpointOrderIllegal"
            variant="danger"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertTriangleIcon"
              />
              <span class="ml-25">
                Illegal checkpoint order: The pick-up needs to be ordered before the drop-off
              </span>
            </div>
          </b-alert>
          <!-- draggable -->
          <draggable
            :list="jobItems"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="(listItem, index) in jobItems"
              :key="index"
              tag="li"
              class="d-flex justify-content-between align-items-center"
            >
              <div class="d-flex">
                <b-avatar>
                  <feather-icon
                    :icon="resolveTimelineTypeIcon(listItem.type)"
                  />
                </b-avatar>

                <div class="ml-1">
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ resolveTimelineTypeText(listItem.type) }}
                  </b-card-text>
                  <small>{{ listItem.description }}</small>
                </div>
              </div>
              <b-button
                v-if="listItem.type !== 'type.pickUp' && listItem.type !== 'type.dropOff'"
                variant="flat-secondary"
                size="sm"
                class="btn-icon"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  size="16"
                  class="align-middle"
                />
              </b-button>
            </b-list-group-item>
          </draggable>
        </b-card>

        <b-row>
          <b-col cols="12" />
        </b-row>
      </b-col>

      <b-col
        cols="12"
        xl="3"
        lg="4"
        md="5"
      >
        <b-card>
          <h6 class="text-primary font-weight-bold mb-2">
            Add Items
          </h6>
          <b-col
            md="12"
            class="mx-0 px-0"
          >
            <validation-observer
              ref="jobItemForm"
              #default="{invalid}"
            >
              <b-form>
                <b-form-group
                  label="Type"
                  label-for="type"
                >
                  <v-select
                    v-model="newJobItem.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="jobItemOptions"
                  >
                    <template #option="{ value, title }">
                      <feather-icon
                        :icon="resolveTimelineTypeIcon(value)"
                        size="16"
                        class="align-middle mr-50"
                      />
                      <span> {{ title }}</span>
                    </template>
                  </v-select>
                </b-form-group>

                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Description"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="description"
                      v-model="newJobItem.description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  :disabled="invalid"
                  variant="info"
                  class="my-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="addItem"
                >
                  Add Item
                </b-button>
              </b-form>
            </validation-observer>
          </b-col></b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BCard,
  BAlert,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BListGroupItem,
  BCardText,
  BSpinner,

} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import jobTemplateStoreModule from '@/views/job-templates/jobTemplateStoreModule'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import draggable from 'vuedraggable'
import useJobProgressList from '@/views/job/jobs-view/useJobProgressList'
import { indexOf } from 'postcss-rtl/lib/affected-props'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BListGroupItem,
    draggable,
    BCardText,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

  setup() {
    const toast = useToast()
    const newJobTemplateData = ref({})
    const jobItems = ref([])
    const JOB_TEMPLATE_APP_STORE_MODULE_NAME = 'app-job-template'

    // Register module
    if (!store.hasModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME, jobTemplateStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(JOB_TEMPLATE_APP_STORE_MODULE_NAME)
    })

    const jobTemplateId = router.currentRoute.params.id

    if (jobTemplateId !== undefined) {
      store.dispatch('app-job-template/fetchJobTemplate', { id: jobTemplateId })
        .then(response => {
          newJobTemplateData.value = response.data
          jobItems.value = response.data.jobItems
        })
        .catch(err => {
          router.replace({ name: 'job-templates-list' })
            .then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Job template not found',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: err.response.data.message || 'There was an error fetching this job template.',
                },
              })
            })
        })
    }

    const jobItemOptions = [
      { title: 'Mileage', value: 'type.mileage' },
      { title: 'Signature', value: 'type.signature' },
    ]

    const { resolveTimelineTypeText, resolveTimelineTypeIcon } = useJobProgressList()

    const pickUp = {
      description: 'Pick up checkpoint',
      type: 'type.pickUp',
    }
    jobItems.value.push(pickUp)
    const dropOff = {
      description: 'Drop off checkpoint',
      type: 'type.dropOff',
    }
    jobItems.value.push(dropOff)

    const checkpointOrderIllegal = computed(() => {
      const pickUpIndex = jobItems.value.findIndex(item => item.type === 'type.pickUp')
      const dropOffIndex = jobItems.value.findIndex(item => item.type === 'type.dropOff')

      return dropOffIndex < pickUpIndex
    })

    const newJobItem = ref({
      type: jobItemOptions[0],
      description: null,
      disabled: true,
    })

    return {
      jobItemOptions,
      jobItems,
      newJobItem,
      newJobTemplateData,

      resolveTimelineTypeText,
      resolveTimelineTypeIcon,

      checkpointOrderIllegal,
    }
  },
  data() {
    return {
      isLoading: false,
      // newJobTemplateData: {},
      required,

    }
  },
  methods: {
    handleUpdateJobTemplate() {
      const jobItems = this.jobItems.map((jobItem, index) => ({
        type: jobItem.type,
        description: jobItem.description,
        position: index,
      }))
      const newTemplateData = {
        alias: this.newJobTemplateData.alias || null,
        jobItems,
      }
      const jobTemplateId = this.newJobTemplateData.id !== undefined ? this.newJobTemplateData.id : null

      if (jobTemplateId) return this.updateJobTemplate(jobTemplateId, newTemplateData)
      return this.addJobTemplate(newTemplateData)
    },
    addJobTemplate(newTemplateData) {
      this.isLoading = true
      store.dispatch('app-job-template/addJobTemplateWithItems', newTemplateData)
        .then(response => {
          this.$router.push({ name: 'job-templates-view', params: { id: response.data.id } })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job Template added',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully added a new job template.',
            },
          })

          this.isLoading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job template update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while adding this job template.',
            },
          })
          this.isLoading = false
        })
    },
    updateJobTemplate(jobTemplateId, jobTemplateData) {
      this.isLoading = true
      store.dispatch('app-job-template/updateJobTemplateWithItems', { id: jobTemplateId, jobTemplateData })
        .then(response => {
          this.$router.push({ name: 'job-templates-view', params: { id: response.data.id } })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job Template updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this job template.',
            },
          })

          this.isLoading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Job template update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this job template.',
            },
          })
          this.isLoading = false
        })
    },
    addItem() {
      const singleJobItem = {
        description: this.newJobItem.description,
        type: this.newJobItem.type.value,
      }

      this.jobItems.push(singleJobItem)
      this.newJobItem = {
        type: this.jobItemOptions[0],
        description: null,
      }
      this.$refs.jobItemForm.reset()
    },
    removeItem(itemIndex) {
      this.jobItems.splice(itemIndex, 1)
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
