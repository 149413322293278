<template>

  <div>
    <vehicle-edit-info
      :is-edit-vehicle-sidebar-active.sync="isEditVehicleSidebarActive"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isEditVehicleSidebarActive = true"
              >
                <span class="text-nowrap">Add Vehicle</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refVehicleListTable"
        class="position-relative"
        :items="fetchVehicles"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Registration -->
        <template #cell(registration)="data">
          <b-media
            vertical-align="center"
          >
            <template #aside>
              <b-avatar
                size="32"
                :text="avatarText(data.item.make)"
                :to="{ name: 'vehicles-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'vehicles-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.registration }}
            </b-link>
            <small class="text-muted">{{ data.item.make + ' ' + data.item.model }}</small>
          </b-media>
        </template>

        <template #cell(make)="data">
          <div class="text-nowrap">
            {{ data.item.make + ' ' + data.item.model }}
          </div>
        </template>

        <template #cell(mileage)="data">
          <div class="text-nowrap">
            {{ data.item.mileage + ' ' + 'MI' }}
          </div>
        </template>

        <template #cell(active)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="`light-${resolveVehicleActiveVariant(data.item.active)}`"
            >
              {{ data.item.active ? 'Active' : 'Inactive' }}
            </b-badge>
          </div>
        </template>

        <template #cell(currentDriver)="data">
          <div class="text-nowrap">
            {{ data.item.currentDriver ? data.item.currentDriver.fullName : "N/A" }}
          </div>
        </template>

        <template #cell(vehicleType)="data">
          <div class="text-nowrap">
            {{ data.item.vehicleType ? data.item.vehicleType.name : "N/A" }}
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'vehicles-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalVehicles"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BRow, BCol, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useVehiclesList from '@/views/vehicle/useVehicleList'
import vehicleStoreModule from '@/views/vehicle/vehicleStoreModule'
import vehicleEditInfo from '@/views/vehicle/vehicles-edit/VehicleEditInfo.vue'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vehicleEditInfo,

    vSelect,
  },
  setup() {
    const VEHICLE_APP_STORE_MODULE_NAME = 'app-vehicle'

    // Register module
    if (!store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.registerModule(VEHICLE_APP_STORE_MODULE_NAME, vehicleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.unregisterModule(VEHICLE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchVehicles,
      tableColumns,
      perPage,
      currentPage,
      totalVehicles,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refVehicleListTable,
      refetchData,

      // UI
      resolveVehicleActiveVariant,

    } = useVehiclesList()

    const isEditVehicleSidebarActive = ref(false)

    return {
      fetchVehicles,
      tableColumns,
      perPage,
      currentPage,
      totalVehicles,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refVehicleListTable,
      refetchData,
      isEditVehicleSidebarActive,
      // Filter
      avatarText,

      // UI
      resolveVehicleActiveVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>