<template>
  <div>
    <vehicle-edit-info
      :is-edit-vehicle-sidebar-active.sync="isEditVehicleSidebarActive"
      :vehicle-data="viewVehicleData"
      @updateVehicleInfo="vehicleViewInfoUpdate"
    />
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :variant="`light-${resolveVehicleActiveVariant(viewVehicleData.active)}`"
              size="104px"
              rounded
            >
              <feather-icon
                :icon="resolveVehicleActiveIcon(viewVehicleData.active)"
                size="70"
              />
            </b-avatar>
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ viewVehicleData.registration }}
                </h4>
                <span class="card-text">{{ viewVehicleData.make + ' ' + viewVehicleData.model }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-dropdown
                  id="dropdown-1"
                  variant="outline-primary"
                >
                  <template #button-content>
                    <b-spinner
                      v-if="isLoading"
                      small
                      class="mr-50"
                    />
                    <span>Actions</span>
                  </template>
                  <b-dropdown-item
                    @click="isEditVehicleSidebarActive = true"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!viewVehicleData.active"
                    variant="outline-warning"
                    @click="toggleVehicleActive"
                  >
                    <feather-icon
                      v-if="!isLoading"
                      icon="CheckIcon"
                      class="mr-50"
                    />
                    <span>Make active</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="viewVehicleData.active"
                    class="text-info"
                    @click="toggleVehicleActive"
                  >
                    <span class="text-primary">
                      <feather-icon
                        v-if="!isLoading"
                        icon="XIcon"
                        class="mr-50"
                      />

                      <span>Make inactive</span>
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="viewVehicleData.status === 'status.inUse'"
                    class="text-info"
                    @click="removeVehicleDriver"
                  >
                    <span class="text-primary">
                      <feather-icon
                        v-if="!isLoading"
                        icon="UserXIcon"
                        class="mr-50"
                      />

                      <span>Remove Driver</span>
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="viewVehicleData.jobCount > 0"
                    variant="text-danger"
                    @click="deleteVehicle(viewVehicleData.id)"
                  >
                    <span class="text-danger">
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">State</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ viewVehicleData.active ? 'Active' : 'Inactive' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="SendIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Mileage</span>
              </th>
              <td class="pb-50">
                {{ viewVehicleData.mileage + ' mi' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Current Driver</span>
              </th>
              <td class="pb-50">
                {{ viewVehicleData.currentDriver ? viewVehicleData.currentDriver.fullName : "N/A" }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="LayersIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Type</span>
              </th>
              <td class="pb-50">
                {{ viewVehicleData.vehicleType ? viewVehicleData.vehicleType.name : "N/A" }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50">
                {{ resolveVehicleStatus(viewVehicleData.status) }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClipboardIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Jobs</span>
              </th>
              <td class="pb-50">
                {{ viewVehicleData.jobCount }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-card-text>
            <b-row
              align-h="end"
              class="text-muted w-100 mt-2 mx-0"
            >
              <b-col
                class="text-right"
              >
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Added: {{ toHumanDate(viewVehicleData.createdAt) }}</span>
              </b-col>
            </b-row>
          </b-card-text>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modal-lg"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="Large Modal"
    >
      <b-card-text>Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly.</b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import toHumanDate from '@/libs/utils/general/dates'
import { ref, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useVehicleList from '@/views/vehicle/useVehicleList'
import vehicleEditInfo from '@/views/vehicle/vehicles-edit/VehicleEditInfo.vue'

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vehicleEditInfo,
  },
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      viewVehicleData: this.$props.vehicleData,
      isLoading: false,
      inactiveVehicleCheckbox: false,
    }
  },
  methods: {
    toggleVehicleActive() {
      const patchData = {
        id: this.viewVehicleData.id,
        active: !this.viewVehicleData.active,
      }
      this.isLoading = true
      store.dispatch('app-vehicle/toggleVehicleActive', patchData)
        .then(response => {
          this.viewVehicleData = response.data
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully made this vehicle active.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this vehicle.',
            },
          })
          this.isLoading = false
        })
    },
    removeVehicleDriver() {
      const patchData = {
        id: this.viewVehicleData.id,
        status: 'status.atBase',
      }
      this.isLoading = true
      store.dispatch('app-vehicle/patchStatus', patchData)
        .then(response => {
          this.viewVehicleData = response.data
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle updated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully updated this vehicle.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while updating this vehicle.',
            },
          })
          this.isLoading = false
        })
    },
    deleteVehicle(id) {
      this.isLoading = true
      store.dispatch('app-vehicle/deleteVehicle', id)
        .then(() => {
          this.$router.push({ name: 'vehicles-list' })
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle deleted',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'You have successfully deleted this vehicle.',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          Object.entries(err.response.data.errors).forEach(errors => {
            const field = errors[0]
            const messages = errors[1]
            const toUppercase = str => str.toUpperCase()
            messages.forEach(errorMessage => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${field.replace(/[A-Z]/g, ' $&').replace(/^./, toUppercase)}`,
                  icon: 'XIcon',
                  variant: 'warning',
                  text: errorMessage,
                },
              })
            })
          })

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Vehicle update failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while deleting this vehicle.',
            },
          })
          this.isLoading = false
        })
    },
    vehicleViewInfoUpdate(data) {
      this.viewVehicleData = data
    },
  },
  setup() {
    const { resolveVehicleActiveVariant, resolveVehicleActiveIcon, resolveVehicleStatus } = useVehicleList()
    const isEditVehicleSidebarActive = ref(false)

    return {
      avatarText,
      resolveVehicleActiveVariant,
      toHumanDate,
      isEditVehicleSidebarActive,
      resolveVehicleActiveIcon,
      resolveVehicleStatus,
    }
  },
}
</script>

<style>

</style>