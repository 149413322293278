<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="vehicleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching vehicle data
      </h4>
      <div class="alert-body">
        No vehicle found with this vehicle id. Check the
        <b-link
          class="alert-link"
          :to="{ name: 'vehicles-list'}"
        >
          Vehicle List
        </b-link>
        for other vehicles.
      </div>
    </b-alert>

    <template v-if="vehicleData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <vehicle-info-card
            :vehicle-data="vehicleData"
          />
        </b-col>
        <b-col>
          <vehicle-view-generate-report-card
            @handleGenerateReport="handleGenerateReport"
            :isLoading="isLoading"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="6"
          md="6"
        >
          <vehicle-check-history-card
            :vehicle-id="vehicleData.id"
          />
        </b-col>

      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import vehicleStoreModule from '@/views/vehicle/vehicleStoreModule'
import vehicleInfoCard from '@/views/vehicle/vehicles-view/VehicleInfoCard.vue'
import vehicleCheckHistoryCard from '@/views/vehicle/vehicles-view/VehicleCheckHistoryCard.vue'
import VehicleViewGenerateReportCard from "@/views/vehicle/vehicles-view/VehicleViewGenerateReportCard";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    VehicleViewGenerateReportCard,
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    vehicleInfoCard,
    vehicleCheckHistoryCard,
  },
  methods: {
    async handleGenerateReport(data) {
      this.isLoading = true
      const reportData = {
        vehicleId: this.vehicleData.id,
        startDate: data.startDate,
        endDate: data.endDate
      }

      store.dispatch('app-vehicle/generateReport', reportData)
        .then(response => {
          const match = response.headers['content-disposition'].match(/filename="?([^"]+)"?/);
          const filename = match ? match[1] : 'report.pdf'
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Report successfully generated',
              icon: 'CheckSquareIcon',
              variant: 'success',
              text: 'Your report has been successfully generated',
            },
          })
          this.isLoading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Report generation failed',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.message || 'An error occurred while generating your report',
            },
          })
          this.isLoading = false
        })
    },
  },
  setup() {
    const vehicleData = ref(null)

    const VEHICLE_APP_STORE_MODULE_NAME = 'app-vehicle'

    // Register module
    if (!store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.registerModule(VEHICLE_APP_STORE_MODULE_NAME, vehicleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLE_APP_STORE_MODULE_NAME)) store.unregisterModule(VEHICLE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-vehicle/fetchVehicle', { id: router.currentRoute.params.id })
      .then(response => {
        vehicleData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          vehicleData.value = undefined
        }
      })
    return {
      vehicleData,
      isLoading: false,
    }
  },
}
</script>

<style>

</style>
