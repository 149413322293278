<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="vehicleCheckData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching vehicle check data
      </h4>
    </b-alert>

    <template v-if="vehicleCheckData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <vehicle-check-info-card
            :vehicle-check-data="vehicleCheckData"
          />
        </b-col>
      </b-row>
      <b-row v-if="vehicleCheckData.checkType === 'type.full' && vehicleCheckData.vehicleCheckAnswers.length > 0" >
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"

        >
          <vehicle-check-answers-card
            :answers="vehicleCheckData.vehicleCheckAnswers"
          />
        </b-col>
      </b-row>
      <b-row
      <b-row v-if="vehicleCheckData.checkType === 'type.full' && vehicleCheckData.images.length > 0">
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <vehicle-check-images-card
            :images="$store.state.appVehicleCheck.images"
          />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import vehicleCheckStoreModule from "@/views/vehicle-check/vehicleCheckStoreModule"
import vehicleCheckInfoCard from '@/views/vehicle-check/vehicle-checks-view/VehicleCheckInfoCard.vue'
import VehicleCheckImagesCard from "@/views/vehicle-check/vehicle-checks-view/VehicleCheckImagesCard";
import VehicleCheckAnswersCard from "@/views/vehicle-check/vehicle-checks-view/VehicleCheckAnswersCard";

export default {
  components: {
    VehicleCheckAnswersCard,
    VehicleCheckImagesCard,
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    vehicleCheckInfoCard,
  },
  methods: {
  },
  setup() {
    const vehicleCheckData = ref(null)

    const VEHICLE_CHECK_APP_STORE_MODULE_NAME = 'appVehicleCheck'

    // Register module
    if (!store.hasModule(VEHICLE_CHECK_APP_STORE_MODULE_NAME)) store.registerModule(VEHICLE_CHECK_APP_STORE_MODULE_NAME, vehicleCheckStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VEHICLE_CHECK_APP_STORE_MODULE_NAME)) {
        store.commit(`${VEHICLE_CHECK_APP_STORE_MODULE_NAME}/CLEAR_IMAGES`)
        return store.unregisterModule(VEHICLE_CHECK_APP_STORE_MODULE_NAME);
      }
    })

    store.dispatch(`${VEHICLE_CHECK_APP_STORE_MODULE_NAME}/fetchVehicleCheck`, { id: router.currentRoute.params.id })
      .then(response => {
        vehicleCheckData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          vehicleCheckData.value = undefined
        }
      })
    store.dispatch(`${VEHICLE_CHECK_APP_STORE_MODULE_NAME}/fetchImageUrls`, { resourceId: router.currentRoute.params.id })
    return {
      vehicleCheckData,
    }
  },
}
</script>

<style>

</style>
