import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://api.recoventry.uk/api/',
  // baseURL: 'https://settling-skilled-drake.ngrok-free.app/api/',
  timeout: 10000,
  headers: {
    'ngrok-skip-browser-warning': true
  }
})

Vue.prototype.$http = axiosIns

export default axiosIns