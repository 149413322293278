<template>
  <div>
    <b-card >
      <b-row>
        <b-col>
          <b-card-text>
            <span class="font-weight-bold">Images</span>
          </b-card-text>
        </b-col>
      </b-row>
      <b-row class="mt-2 px-0 mx-0">
        <swiper
          ref="swiperTop"
          class="swiper-gallery gallery-top"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="(image, index) in images"
            :key="index"
          >
            <b-img
              :src="image"
              fluid
            />
          </swiper-slide>

          <div
            slot="button-next"
            class="swiper-button-next swiper-button-white"
          />
          <div
            slot="button-prev"
            class="swiper-button-prev swiper-button-white"
          />
        </swiper>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BSpinner,
  BDropdown,
  BDropdownItem,
  VBModal,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'swiper/css/swiper.css'

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
    Swiper,
    SwiperSlide,
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BSpinner,
    BDropdown,
    BDropdownItem,
    Swiper,
    SwiperSlide,
    BImg
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper
    })
  },
  data() {
    return {
      swiperOptions: {
        loop: false,
        // loopedSlides: this.imageUrls.length,
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      isLoading: false,
    }
  },
  methods: {
  },
  setup() {
  },
}
</script>